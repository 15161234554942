import Cards from "react-credit-cards";
import { MdCheck } from "react-icons/md";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";

import React, {
  forwardRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import { Row, Col, Spin, message, Typography } from "antd";

import { formatCurrency } from "../../utils";
import { Page } from "../../constants/brokers";
import PixPaymentStep from "../Payment/PixPaymentStep";
import { useBroker } from "../../contexts/BrokerContext";
import CreditCardStep, { ETypePurchase } from "../Payment/CreditCardStep";
import { Button } from "@mui/material";
import { apiPayment } from "../../services/apiPayment";
import { cardAttributes } from "../CardList";
import { CardButton, ModalStyled } from "./style";
import getInstallmentsByPrice from "../../utils/getInstallmentsListByPrice";
import { SelectLabled } from "../../constants/inputs";
import { apiPlan } from "../../services/apiPlan";
import HandleTag from "../../services/handleTag";

interface PaymentModalProps {
  item?: Page;
  show: boolean;
  onCancel?: (e: any) => void;
  paymentData: any;
  callDarf: any;
  hideDarfDownload?: boolean;
  onSucessPix?: (event: any) => void;
  onSucessCreditCard?: (event: any) => void;
  planCurrent?: any;
  disablePixOption?: boolean;
  disableCreditCardOption?: boolean;
  customInstallments?: number;
  maxPixValue?: number;
  maxCCValue?: number;
  selectedYear?: number;
  emitting?: boolean;
  cupom?: any;
  setCupom?: any;
  hidePixButton?: boolean;
  fromDarf?: boolean;
  closeModal?: () => void;
  closeDarfModal?: () => void;
  changeCardAction?: boolean;
  onSuccessGeneral?: () => void;
  blockListCard?: string[];
}

interface PaymentInfoContentProps {
  content: JSX.Element;
  title?: string;
  amount?: number;
}

export interface PaymentDetailsProp {
  value?: number;
  paymentId: string;
  qrCodeLink?: string;
  name?: string;
}

enum EPlanStep {
  CHOOSE_PAYMENT = "CHOOSE_PAYMENT",
  PIX_PAYMENT = "PIX_PAYMENT",
  CC_PAYMENT = "CC_PAYMENT",
  LOADING = "LOADING",
  FINISHED = "FINISHED",
}

let getStatus: NodeJS.Timeout;

export const PaymentModal: React.FC<PaymentModalProps> = ({
  item,
  show,
  onCancel,
  paymentData,
  callDarf,
  closeDarfModal,
  hideDarfDownload,
  disablePixOption,
  disableCreditCardOption,
  onSucessPix,
  planCurrent,
  onSucessCreditCard,
  customInstallments,
  maxPixValue,
  maxCCValue,
  selectedYear,
  emitting = false,
  cupom,
  setCupom,
  hidePixButton,
  fromDarf,
  closeModal,
  changeCardAction,
  blockListCard,
  onSuccessGeneral,
}) => {
  const pagarmeError = false;
  const [installment, setInstallment] = useState(1);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [selectedCard, setSelectedCard] = useState<cardAttributes>();
  const [cardList, setCardList] = useState<cardAttributes[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingCards, setLoadingCards] = useState(false);
  const [step, setStep] = useState<EPlanStep>(EPlanStep.CHOOSE_PAYMENT);
  const [data, setData] = useState({
    darf: { _id: "" },
    payment: { paymentId: 0 },
  });
  const { currentPage, currentBroker } = useBroker();
  
  const getCardList = () => {
    setLoadingCards(true);
    apiPayment
      .get(`/payment/list_cards`)
      .then((ret) => {
        setCardList(ret?.data?.list || []);
      })
      .catch((err) => {
        message.error("Ocorreu um erro ao buscar cartões");
      })
      .finally(() => {
        setLoadingCards(false);
      });
  };

  const checkWasPaid = useCallback(
    (delay: number = 5) => {
      let attempts = 0;
      let check_id: any = data?.payment?.paymentId;

      if (step === "PIX_PAYMENT") {
        check_id = data;
      }

      let backend: any;

      // backend = currentPage?.api || apiPlan;
      backend = apiPayment;

      getStatus = setInterval(async () => {
        backend
          .get(`/payment?id=${check_id}`)
          .then((res: any) => {
            if (res.data?.status === "paid") {
              clearInterval(getStatus);
              setStep(EPlanStep.FINISHED);
              setLoading(false);
              closeModal?.();
              closeDarfModal?.();
              message.success("DARF pago com sucesso!");
            }
          })
          .finally(() => {
            attempts += 1;

            if (attempts > 100) {
              clearInterval(getStatus);
            }
          });
      }, delay * 1000);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      data,
      step,
      currentBroker?.path,
      item,
      // currentPage,
      selectedYear,
    ]
  );

  useEffect(() => {
    getStatus && clearInterval(getStatus);
  }, [step]);

  useEffect(() => {
    if (data?.payment?.paymentId !== 0) {
      checkWasPaid();
    }

    return () => getStatus && clearInterval(getStatus);
  }, [data, checkWasPaid]);

  useEffect(() => {
    if (show) {
      getCardList();
    } else {
      setSelectedCard(undefined);
    }
  }, [show]);

  useEffect(() => {
    if (step === EPlanStep.CHOOSE_PAYMENT) {
      setSelectedCard(undefined);
    }
  }, [step]);

  const changePaymentId = (value: any) => {
    if (step === "CC_PAYMENT") {
      setStep(EPlanStep.LOADING);
      setLoading(true);
    }
    setData(value);
  };

  const purchaseType = useMemo(() => {
    if (planCurrent?.type in ETypePurchase) return planCurrent?.type;

    return ETypePurchase.UNIQUE;
  }, [planCurrent]);

  const hasPlan = [
    "VELOTAX_MAIN_BASIC",
    "VELOTAX_MAIN_PRO",
    "VELOTAX_MAIN_PRO_MONTH",
    "VELOTAX_MAIN_CONCIERGE",
  ].includes(planCurrent?.type || "");

  const value = useMemo(() => {
    const isPlan = [
      "VELOTAX_MAIN_BASIC",
      "VELOTAX_MAIN_PRO",
      "VELOTAX_MAIN_PRO_MONTH",
      "VELOTAX_MAIN_CONCIERGE",
    ].includes(planCurrent?.type || "");

    if (isPlan) {
      var isMonth = planCurrent?.interval === "Mensal";

      var planPrice =
        (!isMonth ? (planCurrent?.price || 0) * 12 : planCurrent?.price) || 0;

      if (cupom?.type === "absolute") {
        planPrice = planPrice - Number(cupom?.discountValue);
      } else if (cupom?.type === "percent") {
        planPrice = planPrice - planPrice * Number(cupom?.discountValue / 100);
      }

      planPrice = Number(planPrice.toFixed(2));

      return planPrice && isMonth ? planPrice * 12 : planPrice;
    }

    return paymentData?.impostoTotalFinal;
  }, [paymentData?.impostoTotalFinal, planCurrent?.interval, planCurrent?.price, planCurrent?.type, cupom]);

  const fixedInstallments = useMemo(
    () => (!!planCurrent?.type ? 12 : undefined),
    [planCurrent?.type]
  );

  const installments = useMemo(
    () => getInstallmentsByPrice(value, !!planCurrent?.type, fixedInstallments),
    [planCurrent, value, fixedInstallments]
  );

  const onChangeInstallments = (value: any) => {
    setInstallment(value);
  };

  const SelectInput = forwardRef((props, ref) => (
    <SelectLabled
      {...props}
      options={installments}
      value={
        fixedInstallments || planCurrent?.type
          ? installments?.[0]?.value
          : installment
      }
      placeholder="Selecione a(s) parcela(s)"
      onChange={onChangeInstallments}
    />
  ));

  const createPlanMainVelotax = () => {
    const url = "/user-plan";

    const cardInfo = { card_id: selectedCard?.id };
    const clientInfo = { customer_id: selectedCard?.customer_id };

    setLoadingPayment(true);

    apiPayment
      .post(url, {
        ...paymentData,
        type: planCurrent?.type,
        from: "XP",
        paymentForm: "credit_card",
        installments: 12,
        cupomCode: cupom?.cupomCode,
        cardInfo,
        clientInfo,
        acceptTerms: true,
      })
      .then((res: any) => {
        if (res.data.paymentId || res.data.paymentRecurringId)
          onSucessCreditCard && onSucessCreditCard(res.data);

        setLoading(true);
        setStep(EPlanStep.LOADING);
      })
      .catch((err) => {
        message.error(err.response?.data?.message);
      })
      .finally(() => {
        setLoadingPayment(false);
      });
  };

  const createUniquePayment = () => {
    const url = "/darf";

    const cardInfo = { card_id: selectedCard?.id };
    const clientInfo = { customer_id: selectedCard?.customer_id };

    setLoadingPayment(true);
    (currentPage?.api || apiPlan)
      .post(url, {
        ...paymentData,
        paymentForm: "credit_card",
        paymentInfo: {
          installments: installment,
          cardInfo: cardInfo,
          clientInfo: clientInfo,
        },
      })
      .then((res: any) => {
        changePaymentId(res?.data);
        setStep(EPlanStep.LOADING);
        setLoading(true);
      })
      .catch((err) => {
        message.error(err.response?.data?.message);
      })
      .finally(() => {
        setLoadingPayment(false);
      });
  };

  const changeCardService = async () => {
    if (!selectedCard?.id) return;

    let cardInfo: any = { card_id: selectedCard?.id };

    setLoadingPayment(true);

    apiPayment
      .post(`/user-plan/change_signature_card`, {
        card_id: cardInfo.card_id,
      })
      .then((ret) => {
        message.success("Cartão atualizado com sucesso!");
      })
      .catch((err) => {
        message.error("Ocorreu um erro ao alterar cartão!");
      })
      .finally(() => {
        setLoadingPayment(false);
        onSuccessGeneral && onSuccessGeneral();
      });
  };

  const handlePay = () => {
    if (changeCardAction) {
      changeCardService();
    } else if (purchaseType === "UNIQUE") {
      createUniquePayment();
    } else if (["VELOTAX_MAIN_BASIC", "VELOTAX_MAIN_PRO", "VELOTAX_MAIN_CONCIERGE"].includes(purchaseType)) {
      createPlanMainVelotax();
    }
  };

  const plansContentDisplay: Record<EPlanStep, PaymentInfoContentProps> = {
    [EPlanStep.CHOOSE_PAYMENT]: {
      content: (
        <Spin spinning={emitting}>
          <Row gutter={[16, 8]}>
            {(disablePixOption || disableCreditCardOption) && fromDarf && (
              <Col span={24}>
                <Typography.Paragraph
                  style={{
                    color: "var(--velotax-font-color-dark)",
                    textAlign: "justify",
                  }}
                >
                  <InfoCircleOutlined />
                  &nbsp;&nbsp;Não aceitamos o pagamento de DARFs com valor acima
                  de <strong>{formatCurrency(maxPixValue || 0)}</strong> através
                  de PIX ou cartão de crédito. Faça o download do seu DARF e
                  realize o pagamento diretamente no aplicativo do seu banco.{" "}
                </Typography.Paragraph>
              </Col>
            )}
            {!disableCreditCardOption && !pagarmeError && (
              <>
                {loadingCards ? (
                  <Col span={24} style={{ marginBottom: "16px" }}>
                    <Row justify="center" align="middle">
                      <Col style={{ color: "var(--velotax-font-color-dark)" }}>
                        <LoadingOutlined
                          style={{ color: "var(--ant-primary-color)" }}
                        />
                        &nbsp;&nbsp;&nbsp;Carregando seus cartões
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col span={24} style={{ marginBottom: "24px" }}>
                    <Row gutter={[8, 8]}>
                      {cardList
                        .filter(
                          (card) => !selectedCard || selectedCard.id === card.id
                        )
                        .map((card) => {
                          if (
                            blockListCard?.length &&
                            blockListCard.includes(card.id)
                          )
                            return undefined;
                          return (
                            <Col span={24} key={card.id}>
                              <CardButton
                                size="large"
                                color="info"
                                variant="outlined"
                                className="full-width"
                                onClick={() => setSelectedCard(card)}
                                disableTouchRipple={
                                  selectedCard?.id === card.id
                                }
                                style={{
                                  cursor:
                                    selectedCard?.id === card.id
                                      ? "default"
                                      : "pointer",
                                }}
                              >
                                <Row
                                  style={{ width: "100%" }}
                                  align="middle"
                                  justify="space-between"
                                >
                                  <Col>
                                    <Cards
                                      preview
                                      cvc="***"
                                      name=""
                                      expiry=""
                                      number={
                                        card.first_six_digits +
                                        "** *****" +
                                        card.last_four_digits
                                      }
                                    />
                                  </Col>
                                  <Col>
                                    {"**** **** **** " + card.last_four_digits}
                                  </Col>
                                  {/* <Col span={24}>
                                    {selectedCard && (
                                      <div style={{ margin: "16px 0" }}>
                                        <SelectInput />
                                      </div>
                                    )}
                                  </Col> */}
                                </Row>
                              </CardButton>
                            </Col>
                          );
                        })}
                    </Row>
                  </Col>
                )}
              </>
            )}
            {selectedCard ? (
              <>
                {(selectedCard && !hasPlan) && <Col span={24}>
                  <div
                    style={{
                      padding: "8px 16px",
                      backgroundColor: "var(--white)",
                    }}
                  >
                    <SelectInput />
                  </div>
                </Col>}
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 25,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "97%",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      size="large"
                      variant="text"
                      onClick={() => setSelectedCard(undefined)}
                    >
                      <span className="xp-link uppercase">Voltar</span>
                    </Button>

                    <Button
                      size="large"
                      color="secondary"
                      variant="contained"
                      onClick={handlePay}
                      disabled={loadingPayment}
                    >
                      {loadingPayment && <LoadingOutlined />}
                      &nbsp;&nbsp;Finalizar pagamento
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <>
                {!pagarmeError && (
                  <Col span={24}>
                    <Button
                      size="large"
                      color="secondary"
                      variant="contained"
                      className="full-width"
                      disabled={disableCreditCardOption}
                      onClick={() => {
                        setStep(EPlanStep.CC_PAYMENT);
                      }}
                    >
                      {cardList.length > 0
                        ? "Novo cartão de crédito"
                        : "Cartão de crédito"}
                    </Button>
                  </Col>
                )}
                <Col span={24}>
                  {!hidePixButton && (
                    <Button
                      size="large"
                      color="secondary"
                      variant="contained"
                      className="full-width"
                      disabled={disablePixOption}
                      onClick={() => {
                        setStep(EPlanStep.PIX_PAYMENT);
                        if (!paymentData?.impostoTotalFinal) {
                          HandleTag("55");
                        }
                      }}
                    >
                      PIX à vista
                    </Button>
                  )}
                </Col>

                {!hideDarfDownload && (
                  <Col span={24}>
                    <Button
                      size="large"
                      color="secondary"
                      variant="contained"
                      className="full-width"
                      onClick={() => callDarf(true)}
                    >
                      Boleto DARF
                    </Button>
                  </Col>
                )}
              </>
            )}
          </Row>
        </Spin>
      ),
      title: selectedCard
        ? "Cartão de crédito"
        : changeCardAction
        ? "Trocar Cartão:"
        : "Escolha sua forma de pagamento:",
    },
    [EPlanStep.PIX_PAYMENT]: {
      content: (
        <PixPaymentStep
          paymentData={paymentData}
          cupom={cupom}
          handleIdBack={changePaymentId}
          onSucess={onSucessPix}
          backStep={() => {
            setData({ darf: { _id: "" }, payment: { paymentId: 0 } });
            setStep(EPlanStep.CHOOSE_PAYMENT);
          }}
        />
      ),
      title: "Pagamento com PIX à vista",
    },
    [EPlanStep.CC_PAYMENT]: {
      content: (
        <CreditCardStep
          initialized={true}
          closeMainModal={() => closeModal && closeModal()}
          typePurchase={purchaseType}
          handleClosableModal={() => {}}
          status=""
          cupom={cupom}
          plan={planCurrent}
          handleSuccess={onSucessCreditCard}
          setInitialized={() => {}}
          darfData={paymentData}
          changeCardAction={changeCardAction}
          handleIdBack={changePaymentId}
          customInstallments={customInstallments}
          backToRoot={() => setStep(EPlanStep.CHOOSE_PAYMENT)}
        />
      ),
      title: "Cartão de crédito",
    },
    [EPlanStep.LOADING]: {
      title: "Pagamento",
      content: (
        <Row>
          <Col
            span={24}
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "15px",
            }}
          >
            <Spin spinning={loading}></Spin>
            <span style={{ marginLeft: "10px", color: "#000" }}>
              Processando pagamento...
            </span>
          </Col>
        </Row>
      ),
    },
    [EPlanStep.FINISHED]: {
      content: (
        <Row>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <MdCheck color={"var(--ant-success-color)"} size={120} />
          </Col>
          <span
            style={{
              marginTop: "10px",
              fontSize: "16px",
              textAlign: "center",
              marginBottom: "35px",
              fontWeight: "500",
              width: "100%",
            }}
          >
            Seu pagamento foi realizado com sucesso!
          </span>
        </Row>
      ),
      title: "Confirmação de Pagamento",
    },
  };

  const title = plansContentDisplay?.[step]?.title;
  const content = plansContentDisplay?.[step]?.content;

  const cancelActions = (e: any) => {
    setStep(EPlanStep.CHOOSE_PAYMENT);
    clearInterval(getStatus);
    setData({ darf: { _id: "" }, payment: { paymentId: 0 } });
    setCupom?.(undefined);
    if (onCancel) {
      onCancel(e);
    }
  };

  return (
    <ModalStyled
      title={title}
      visible={show}
      footer={null}
      width={580}
      maskClosable={false}
      keyboard={false}
      className="payment-modal"
      onCancel={(e) => cancelActions(e)}
      bodyStyle={
        step !== EPlanStep.CHOOSE_PAYMENT && step !== EPlanStep.PIX_PAYMENT
          ? {
              minHeight: "150px",
            }
          : {}
      }
    >
      {content}
    </ModalStyled>
  );
};
